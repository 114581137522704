import { combineReducers } from 'redux'

import magnets from './magnets'
import refrigerator from './refrigerator'
import output from './output'

export const rootReducer = combineReducers({
  magnets,
  refrigerator,
  output,
})
