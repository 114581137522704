import {
  T,
  always,
  compose,
  cond,
  curry,
  entries,
  get,
  identity,
  inRange,
  isEmpty,
  isString,
  map,
  multiply,
  reduce,
  reject,
  replace,
  startsWith,
} from 'lodash/fp'
import qs from 'querystring'

// @private
// Returns path without leading and/or trailing '/'.
const _sanitizePath = replace(/(^\/|\/$)/g, '')

// Returns true if the URL is internal, false otherwise.
export const isInternal = s => isString(s) && /^\/(?!\/)/.test(s)

// Constant is true if we are in the browser, false otherwise.
export const isBrowser = typeof window !== 'undefined'

// Returns true if we are in the largest desktop, false otherwise.
export const isLargestDesktop = () => {
  if (!isBrowser) return false
  return window.matchMedia('(min-width: 90rem)').matches
}

// Converts a 0-1 float to a string representation of a percentage.
export const toPercentage = compose(
  x => x + '%',
  multiply(100)
)

// Returns the value of a given key in the location search query.
export const getSearchQuery = curry((key = '?query', location) =>
  compose(
    get(key),
    qs.parse,
    get('search')
  )(location)
)

// If the argument is empty, returns `undefined`. If non-empty, it is returned
// as is.
export const strictNonEmpty = cond([
  [isEmpty, always(undefined)],
  [T, identity],
])

// Lodash's get function, but if the value is empty via `isEmtpy`, it returns
// `undefined`.
export const getUnlessEmpty = compose(
  strictNonEmpty,
  get
)

// Lodash's get function, but specialized for Prismic Rich Text fields. Gets
// the text value of the field and returns the HTML value if it is not empty.
export const getRichText = compose(
  cond([
    [
      compose(
        isEmpty,
        get('text')
      ),
      always(undefined),
    ],
    [T, get('html')],
  ]),
  get
)

// Lodash's map function with indexes. Each item in the collection is converted
// to a key-value tuple.
export const mapIndexed = curry((iteratee, collection) =>
  compose(
    map(iteratee),
    entries
  )(collection)
)

// Returns an array of nodes from the connection data provided as a result of a
// GraphQL query.
export const nodes = compose(
  map('node'),
  get('edges')
)

// Returns true if path is a child of the current location, false otherwise.
export const isPathActive = curry((location, path) => {
  const sanitizedPath = _sanitizePath(path)
  const sanitizedLocationPath = compose(
    _sanitizePath,
    get('pathname')
  )(location)

  if (isEmpty(sanitizedPath) || isEmpty(sanitizedLocationPath)) return false

  return startsWith(sanitizedLocationPath, sanitizedPath)
})

// Splits an array into an array of arrays determined using the provided
// predicate. If the predicate returns true, a new array is started.
export const splitArray = (
  arr = [],
  predicate = () => false,
  removeEmpty = true
) => {
  const result = reduce(
    (acc, curr, idx) => {
      if (predicate(curr, idx)) {
        acc = acc.concat([[]])
        return acc
      }

      acc[acc.length - 1] = acc[acc.length - 1].concat([curr])
      return acc
    },
    [[]],
    arr
  )

  if (removeEmpty) return reject(isEmpty, result)
  return result
}

// Posts a form to Netlify with the provided form name and values. Returns true
// if successful, false otherwise.
export const postNetlifyForm = async (formName, values) => {
  const encode = data => {
    const formData = new FormData()

    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }

    return formData
  }

  try {
    const response = await fetch('/', {
      method: 'post',
      body: encode({ 'form-name': formName, ...values }),
    })

    return compose(
      inRange(200, 300),
      get('status')
    )(response)
  } catch (error) {
    console.error(error)
    return false
  }
}
