import { createAction, handleActions } from 'redux-actions'
import { pick } from 'lodash/fp'

export const SET_DIMENSIONS = 'wtw-4268/refrigerator/SET_DIMENSIONS'
export const SET_BACKGROUND = 'wtw-4268/refrigerator/SET_BACKGROUND'

const initialState = {
  width: 0,
  height: 0,
  top: 0,
  x: 0,
  y: 0,
  background: 1,
}

export default handleActions(
  {
    // Sets dimension data.
    [SET_DIMENSIONS]: (state, { payload }) => ({
      ...state,
      ...pick(['width', 'height', 'top', 'x', 'y'], payload),
    }),

    // Sets background.
    [SET_BACKGROUND]: (state, { payload }) => ({
      ...state,
      background: payload,
    }),
  },
  initialState
)

export const setDimensions = createAction(SET_DIMENSIONS)
export const setBackground = createAction(SET_BACKGROUND)
