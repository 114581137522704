import { createStore as reduxCreateStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import { rootReducer } from 'src/modules'

export const createStore = () => {
  const store = reduxCreateStore(rootReducer, applyMiddleware(thunk, logger))

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('src/modules', async () => {
      const { rootReducer: nextRootReducer } = await import('src/modules')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
