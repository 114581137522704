module.exports = [{
      plugin: require('/Users/angeloashmore/projects/web/WTW_4268/plugins/gatsby-plugin-redux/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/angeloashmore/projects/web/WTW_4268/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"head":false},
    },{
      plugin: require('/Users/angeloashmore/projects/web/WTW_4268/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
