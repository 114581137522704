import { createAction, handleActions } from 'redux-actions'

export const SET_PHOTO_URL = 'wtw-4268/output/SET_PHOTO_URL'
export const SET_PHOTO_ID = 'wtw-4268/output/SET_PHOTO_ID'

const initialState = {
  photoURL: null,    
  photoID: null,  
}

export default handleActions(
  {
    // Sets dimension data.
    [SET_PHOTO_URL]: (state, { payload }) => ({      
      ...state,
      photoURL: payload,      
    }),
    [SET_PHOTO_ID]: (state, { payload }) => ({      
      ...state,
      photoID: payload,      
    }),
  },
  initialState
)

export const setPhotoURL = createAction(SET_PHOTO_URL)
export const setPhotoID = createAction(SET_PHOTO_ID)
