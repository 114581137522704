exports.onClientEntry = () => {
  // Fetch polyfill (IE)
  import('isomorphic-fetch')

  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === 'undefined') {
    import('intersection-observer')
    console.log('Polyfill loaded: intersection-observer')
  }
}

exports.onRouteUpdate = async () => {
  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement('img')
  if (
    typeof testImg.style.objectFit === 'undefined' ||
    typeof testImg.style.objectPosition === 'undefined'
  ) {
    ;(await import('object-fit-images')).default()
    console.log('Polyfill loaded: object-fit-images')
  }
}
