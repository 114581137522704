import { createAction, handleActions } from 'redux-actions'
import {
  __,
  compose,
  concat,
  get,
  pick,
  propEq,
  reject,
  sample,
  shuffle,
  size,
} from 'lodash/fp'
import md5 from 'md5'

import { mapIndexed, isLargestDesktop } from 'src/helpers'

import intros from 'src/content/intros.json'
import adjectives from 'src/content/adjectives.json'
import nouns from 'src/content/nouns.json'
import closings from 'src/content/closings.json'

export const SET_POSITION = 'wtw-4268/magnets/SET_POSITION'
export const REMOVE_POSITION = 'wtw-4268/magnets/REMOVE_POSITION'
export const GENERATE_MESSAGE = 'wtw-4268/magnets/GENERATE_MESSAGE'
export const GENERATE_MESSAGE_ENHANCED =
  'wtw-4268/magnets/GENERATE_MESSAGE_ENHANCED'

const allItems = shuffle([...intros, ...adjectives, ...nouns, ...closings])

const initialState = {
  positions: [],
  items: compose(
    mapIndexed(([index, value]) => ({ id: md5(value), value })),
    shuffle
  )(allItems),
}

export default handleActions(
  {
    // Sets position data for a given ID.
    [SET_POSITION]: (state, { payload }) => ({
      ...state,
      positions: compose(
        concat(__, [pick(['id', 'x', 'y'], payload)]),
        reject(propEq('id', get('id', payload))),
        get('positions')
      )(state),
    }),

    // Removes position data for a given ID.
    [REMOVE_POSITION]: (state, { payload }) => ({
      ...state,
      positions: compose(
        reject(propEq('id', get('id', payload))),
        get('positions')
      )(state),
    }),

    // Randomly selects magnets and places in correct locations.
    [GENERATE_MESSAGE_ENHANCED]: (state, { payload }) => {
      const { dropzoneWidth, dropzoneHeight } = payload

      const approximateLetterWidth = isLargestDesktop() ? 14.1 : 10.7866
      const approximateLetterHeight = isLargestDesktop() ? 29 : 21.17
      const offsetY = isLargestDesktop() ? 0.25 : 0.12
      const offsetLinesY = isLargestDesktop() ? 0.1 : 0.15

      const estimateLength = word =>
        (size(word) * approximateLetterWidth) / dropzoneWidth
      const calculateX = word => 0.5 - estimateLength(word) / 2
      const calculateY = (line = 1, word) =>
        offsetY + offsetLinesY * line - approximateLetterHeight / dropzoneHeight

      const intro = sample(intros)
      const adjective = sample(adjectives)
      const noun = sample(nouns)
      const closing = sample(closings)

      return {
        ...state,
        positions: [
          { id: md5(intro), x: calculateX(intro), y: calculateY(1, intro) },
          {
            id: md5(adjective),
            x: calculateX(adjective),
            y: calculateY(2, adjective),
          },
          { id: md5(noun), x: calculateX(noun), y: calculateY(3, noun) },
          {
            id: md5(closing),
            x: calculateX(closing),
            y: calculateY(4, closing),
          },
        ],
      }
    },
  },
  initialState
)

export const setPosition = createAction(SET_POSITION)
export const removePosition = createAction(REMOVE_POSITION)
export const generateMessageEnhanced = createAction(GENERATE_MESSAGE_ENHANCED)
export const generateMessage = payload => (dispatch, getState) => {
  const state = getState()

  dispatch(
    generateMessageEnhanced({
      dropzoneWidth: get('refrigerator.width', state),
      dropzoneHeight: get('refrigerator.height', state),
    })
  )
}
